/*-----------------------------------*/
/* CUSTOM BOOTSTRAP CONFIGURATION    */
/* npm install -g sass               */
/* sass --watch file.scss:out.css    */
/*-----------------------------------*/

/* GRID */
$enable-flex: true;
$grid-columns: 8;
$grid-gutter-width: 24px; 
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1360px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px
);

/* Customization */
$enable-rounded: false;

/* Colors */
$primary:       #f21c0a;
$secondary:     #fff;
$body-color:	#000;
$gray4: #f1f1f1;

$border-radius:	0; /* entire bootstrap */
$border-color:	#bfbfbf;

/* $success:       $green !default; */
/* $info:          $cyan !default; */
/* $warning:       $yellow !default; */
/* $danger:        $red !default; */
/* $light:         $gray-100 !default; */
/* $dark:          $gray-800 !default; */

/* Theme */
$link-color:		#000;
$link-decoration:	underline;
$link-hover-color:	#f21c0a;

$text-muted: #4d4d4d;

// Fonts
//
$font-size-base:  1.062rem; // 17px
$font-size-lg:      ($font-size-base * 1.25);
$font-size-sm:      ($font-size-base * .875);

$line-height-lg:  1.18; // 20px
$line-height-sm:  1.18;
$line-height-base: 1.18;

$font-weight-light:  300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold:   700 !default;

$font-weight-base:   $font-weight-normal !default;
$line-height-base:   1.5 !default;

$h1-font-size:  $font-size-base * 2.5;
$h2-font-size:  $font-size-base * 2;
$h3-font-size:  $font-size-base * 1.75;
$h4-font-size:  $font-size-base * 1.5;
$h5-font-size:  $font-size-base * 1.25;
$h6-font-size:  $font-size-base;

$hr-margin-y:	$font-size-base;

/* Buttons */
$input-btn-padding-y:   0.3529411764705882rem; 
$input-btn-padding-x:   0.5882352941176471rem;
$input-btn-line-height: 1;

/* Inputs */
$input-border-color:  #000; 
$input-line-height:   1.18;
$input-border-width: 1px;
$input-height-border:  $input-border-width;
$input-placeholder-color: #a3a3a3;
$input-focus-border-color: $input-border-color;
$input-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-btn-focus-color: transparent;

/* Buttons */
$input-btn-focus-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$close-color: $primary;

/* Dropdown */
$dropdown-spacer:	0rem;

/* Cards */
$card-border-width:	0;
$card-border-color:	$border-color;
$card-cap-bg:		transparent;
$card-bg:			#fff;

/* Jumbotron */
$jumbotron-padding:   1rem;
$jumbotron-bg:        $gray4;

/* Navs */ 
$nav-link-padding-y:	1rem;
$nav-link-padding-x:	1rem;


/* list group - menu v katalogu */

$list-group-active-bg: white; 
$list-group-active-color: $primary;
$list-group-active-border-color: theme-color("primary");
 
/* Breadcrumbs */

$breadcrumb-padding-y:              .75rem;
$breadcrumb-padding-x:              1rem;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          #4d4d4d;
$breadcrumb-active-color:           #4d4d4d;
$breadcrumb-divider:                quote("/");

/* Required */
@import "../../../../vendor/bootstrap/scss/functions";
@import "../../../../vendor/bootstrap/scss/variables";  
@import "../../../../vendor/bootstrap/scss/mixins";
@import "../../../../vendor/bootstrap/scss/transitions"; 
@import "../../../../vendor/bootstrap/scss/images"; 
@import "../../../../vendor/bootstrap/scss/reboot";
@import "../../../../vendor/bootstrap/scss/utilities";
@import "../../../../vendor/bootstrap/scss/close";
@import "../../../../vendor/bootstrap/scss/type"; 
@import "../../../../vendor/bootstrap/scss/nav"; 
@import "../../../../vendor/bootstrap/scss/breadcrumb"; 
@import "../../../../vendor/bootstrap/scss/input-group"; 
@import "../../../../vendor/bootstrap/scss/jumbotron"; 
@import "../../../../vendor/bootstrap/scss/buttons"; 
@import "../../../../vendor/bootstrap/scss/forms"; 
@import "../../../../vendor/bootstrap/scss/card"; 
@import "../../../../vendor/bootstrap/scss/dropdown";
@import "../../../../vendor/bootstrap/scss/grid";
@import "../../../../vendor/bootstrap/scss/carousel";
@import "../../../../vendor/bootstrap/scss/list-group";      

/* Responsive typography */

html {
  font-size: 0.9rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.065rem;
  }
}

/* Grid pre karty */

@mixin make-card-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-card-tile {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty
    padding-right: ($gutter * 0.5);
    padding-left: ($gutter * 0.5);
  }

  .card-grid {
  	display: flex;
  	flex-wrap: wrap;
  	margin-right: -($gutter * 0.5);
  	margin-left: -($gutter * 0.5);
  }

  .card-tile > * {
    width: 100%;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .card-grid#{$infix}-#{$i} .card-tile {
        @extend %grid-card-tile;
      }
    }
    
    .card-grid#{$infix} .card-tile,
    .card-grid#{$infix}-auto {
      @extend %grid-card-tile;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .card-grid#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
  		  display: flex;
  	  	flex-wrap: wrap;
  	  	margin-right: -($gutter * 0.5);
  	  	margin-left: -($gutter * 0.5);        
      }
      .card-grid#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .card-grid#{$infix}-#{$i} .card-tile {
          @include make-col($i, $columns);
        }
      }

      //.card-order#{$infix}-first { order: -1; }

      //.card-order#{$infix}-last { order: $columns + 1; }

      //@for $i from 0 through $columns {
      //  .card-order#{$infix}-#{$i} { order: $i; }
      //}

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .card-grid-offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}


@if $enable-grid-classes {
  @include make-card-columns();
}


.jumbotron {
  padding: $jumbotron-padding;
}

.accordion .card:only-child {
  @include border-radius($card-border-radius);
}

.close {
  opacity: 1;
}